import ProviderWS from "../../Provider";
import stateMachine, {
  initialState,
  onMessage,
  onConnection,
  onDisconnection,
} from "./stateMachine";
import PageRedux from "../../ui/Page/PageRedux";

import StateRouter from "./StateRouter";

import ConnectionIndicator from "../../ui/ConnectionIndicator";

window.updateUnlocked = false;

const Quiz = ({
  match: {
    params: { hash, overlay },
  },
}) => {
  const state = {
    ...initialState,
    // step: 'question',
  };

  return (
      <ProviderWS
        namespace="/quiz_tv"
        state={state}
        hash={hash}
        autoConnect={true}
        reducers={stateMachine}
        onMessage={onMessage}
        onConnection={onConnection}
        onDisconnection={onDisconnection}
      >

      <PageRedux className="Quiz">
        <StateRouter />

        <div className="Quiz__connection-indicator">
          <ConnectionIndicator hidePositive={true} />
        </div>
      </PageRedux>
      <div className="full-window full-window--overlay" />
    </ProviderWS>
  );
};

export default Quiz;
