import { useSelector } from "react-redux";
import PlayerList from "./helpers/player/List";
import I18n from "../../../ui/I18n";
import Timer from "../../../ui/Timer";
import Wave from "../../../ui/Waves";

const Question = () => {
  const players = useSelector((state) => state.stateMachine.players);
  const answers = useSelector((state) => state.stateMachine.answers);
  const lang = useSelector((state) => state.stateMachine.lang);

  const {
    question,
    phase,
    index: questionIndex,
    answer,
    banner,
  } = useSelector((state) => state.stateMachine.question);

  const roundScoreboard = players.map((player) => {
    let playerScore = {
      correct: null,
      score: 0,
    };

    if (answers[player.userID]) {
      const { correct, score } = answers[player.userID];

      playerScore = { correct, score };
    }

    return {
      ...player,
      ...playerScore,
    };
  });

  const firstAnnouncement = questionIndex === 1 ? "firstAnnouncement" : "";

  return (
    <>
      <div className={`QuestionPage__content ${phase}`}>
        <div className="QuestionPage__header">
          <div className="QuestionPage__header-index header">
            <div className="index">
              <I18n t="quiz.question.title" params={{ questionIndex }} />
            </div>

            <div className="instruction">
              <I18n t="quiz.question.ready" />
            </div>
          </div>

          <div className="QuestionPage__header-progress progress">
            <Timer width="100" />
          </div>
        </div>

        <div className="QuestionPage__question question">
          <div className="QuestionPage__question-banner banner">
            <img src={banner} />
          </div>

          <div className="QuestionPage__question-label">
            <div
              className="label"
              dangerouslySetInnerHTML={{
                __html: question,
              }}
            ></div>

            <div className="answer">
              <I18n
                t="quiz.question.answer"
                params={{ answer: answer?.title[lang] }}
              />
            </div>
          </div>
        </div>
        <div className={`QuestionPage__playerlist`}>
          <PlayerList players={roundScoreboard} isGameStarted={true} />
        </div>
      </div>
    </>
  );
};

export default Question;
