import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import car from "../../../../../assets/decorations/car.svg";
import flag from "../../../../../assets/decorations/flag.svg";
import winner from "../../../../../assets/decorations/correct.png";
import loser from "../../../../../assets/decorations/wrong.png";

const Player = ({ player }) => {
  const playerData = useSelector(
    (state) => state.stateMachine.answers[player.userID]
  );

  const progress = playerData?.progress || 0;

  const [scoreWord, setScoreWord] = useState(null);

  useEffect(() => {
    if (playerData?.correct) {
      setScoreWord(playerData?.word);
    } else {
      setScoreWord("wrong");
    }
  }, [playerData]);

  const handleArtifactAnimationEnd = () => {
    setTimeout(() => {
      setScoreWord(null);
    }, 500);
  };

  return (
    <div className="GamePage-Player">
      <div className="GamePage-Player__avatar">
        <div className="GamePage-Player__profile">
          <img src={player.profilePicture} alt="profile" />
        </div>

        <div className="GamePage-Player__name">{player.initials}</div>
      </div>
      <div
        className="GamePage-Player__progress"
        style={{ "--progress": progress }}
      >
        <div
          className="GamePage-Player__progress-indicator"
          style={{ "--progress": progress }}
        >
          <div
            onTransitionEnd={handleArtifactAnimationEnd}
            className={`GamePage-Player__progress-indicator-artifact ${
              playerData?.correct ? "correct" : "wrong"
            } ${scoreWord && "animate"}`}
          >
            {playerData?.correct ? (
              <>
                <img src={winner} alt="winner" className="winner" />
                <div>{scoreWord}</div>
              </>
            ) : (
              <img src={loser} alt="wrong" />
            )}
          </div>
          <img src={car} alt="car" className="car-image" />
        </div>
        <div className="GamePage-Player__score">
          <div className="GamePage-Player__score-value">
            {(playerData && playerData?.score) || 0} pts
          </div>
          <img className="GamePage-Player__score-image" src={flag} alt="flag" />
        </div>
      </div>
    </div>
  );
};

export default Player;
