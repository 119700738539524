// https://codepen.io/davidkmuy/pen/NWyPqdX
const confetti = () => {
  var COLORS,
    Confetti,
    NUM_CONFETTI,
    PI_2,
    canvas,
    confetti,
    context,
    drawCircle,
    i,
    range,
    resizeWindow,
    xpos,
    currentSize;

  NUM_CONFETTI = 350;

  COLORS = [
    [242, 206, 27],
    [217, 170, 30],
    [191, 130, 17],
    [217, 175, 98],
    [242, 214, 128]
  ];

  PI_2 = 2 * Math.PI;

  canvas = document.getElementById("confetti-canvas");
  context = canvas.getContext("2d");

  window.w = 0;

  window.h = 0;

  currentSize = 0;

  resizeWindow = function () {
    window.w = canvas.width = window.innerWidth;
    return (window.h = canvas.height = window.innerHeight);
  };

  window.addEventListener("resize", resizeWindow, false);

  window.onload = function () {
    return setTimeout(resizeWindow, 0);
  };

  range = function (a, b) {
    return (b - a) * Math.random() + a;
  };

  drawCircle = function (x, y, r, style) {
    context.beginPath();
    context.arc(x, y, r, 0, PI_2, false);

    context.fillStyle = style;
    return context.fill();
  };

  xpos = 0.5;

  // document.onmousemove = function(e) {
  //   return xpos = e.pageX / w;
  // };

  window.requestAnimationFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        return window.setTimeout(callback, 1000 / 60);
      }
    );
  })();

  Confetti = class Confetti {
    constructor() {
      this.style = COLORS[~~range(0, 5)];
      this.rgb = `rgba(${this.style[0]},${this.style[1]},${this.style[2]}`;
      this.r = ~~range(2, 6);
      this.r2 = 2 * this.r;
      this.replace();
    }

    replace() {
      this.opacity = 0;
      this.dop = 0.02 * range(1, 4);
      this.x = range(-this.r2, window.w - this.r2);
      this.y = range(-20, window.h - this.r2);
      this.xmax = window.w - this.r;
      this.ymax = window.h - this.r;
      this.vx = range(0, 2) + 8 * xpos - 5;
      return (this.vy = 0.7 * this.r + range(-1, 1));
    }

    draw() {
      var ref;
      this.x += this.vx;
      this.y += this.vy;
      this.opacity += this.dop;
      if (this.opacity > 1) {
        this.opacity = 1;
        this.dop *= -1;
      }
      if (this.opacity < 0 || this.y > this.ymax) {
        this.replace();
      }
      if (!(0 < (ref = this.x) && ref < this.xmax)) {
        this.x = (this.x + this.xmax) % this.xmax;
      }
      return drawCircle(
        ~~this.x,
        ~~this.y,
        this.r,
        `${this.rgb},${this.opacity})`
      );
    }
  };

  confetti = (function () {
    var j, ref, results;
    results = [];
    for (
      i = j = 1, ref = NUM_CONFETTI;
      1 <= ref ? j <= ref : j >= ref;
      i = 1 <= ref ? ++j : --j
    ) {
      results.push(new Confetti());
    }
    return results;
  })();

  const step = function () {
    var c, j, len, results;
    requestAnimationFrame(step);
    context.clearRect(0, 0, window.w, window.h);
    results = [];

    if (currentSize < NUM_CONFETTI) {
      currentSize += 1;
    }

    for (j = 0, len = currentSize; j < len; j++) {
      c = confetti[j];
      results.push(c.draw());
    }
    return results;
  };
  requestAnimationFrame(() => {
    resizeWindow();
    step();
  });
};

export default confetti;
