import { useSelector } from "react-redux";
import { useState } from "react";

import Podium from "./pages/Podium";
import Waiting from "./pages/Waiting";

import StepTransition from "../../ui/StepTransition";

const stepTransitionMapper = {
  "null:podium": "none",
  "podium:podium": "static",
  "podium:waiting": "fade",
  "waiting:waiting": "static",
  "waiting:podium": "fade",
};

const StateRouter = () => {
  let currentStep = useSelector((state) => state.stateMachine.step);
  const [previousStep, setPreviousStep] = useState("null");

  const stepComponents = {
    podium: <Podium />,
    waiting: <Waiting />,
  };

  const animation =
    stepTransitionMapper[`${previousStep}:${currentStep}`] || "static";

  const animationEnd = () => {
    console.log("animation done", currentStep);

    setPreviousStep(currentStep);
  };

  return (
    <StepTransition
      animation={animation}
      onAnimationEnd={animationEnd}
      onTransitionEnd={animationEnd}
    >
      <div className="previous">
        {previousStep !== currentStep && stepComponents[previousStep]}
      </div>

      <div className="current">{stepComponents[currentStep]}</div>
    </StepTransition>
  );
};

export default StateRouter;
