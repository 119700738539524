import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  step: 'home',
  lang: 'fr-CA',
  secondsLeft: 5,
  connected: false,
  displayCountdown: true,
  players: [],
  playersData:{},
  question: {
    phase: 'announcement',
    index: 0,
    question: '',
  },
  countdown: {
    total: 10,
    leftTime: 5
  },
  answers: {}
}

export const stateMachine = createSlice({
  name: 'stateMachine',
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param } }) => {
      console.log(type, JSON.stringify({type:'stateMachine/onMessage', payload:{type, param}}));

      switch(type){
        case 'state':
          state.step = param;
          break;

        case 'countdown':
          state.countdown = {
            ...state.countdown,
            ...param
          };
          break;

        case 'round_announcement':
          state.step = 'question';
          state.question = {
            ...state.question,
            phase: 'announcement',
            index: param.questionIndex
          };

          state.playersData = {};
          break;

        case 'round_answering':
          state.step = 'question';
          state.question = {
            ...state.question,
            phase: 'answering'
          };
          break;

        case 'round_score':
          state.step = 'question';
          state.question = {
            ...state.question,
            phase: 'score'
          };

          state.playersData = param.reduce((data, current) => {
            const { userID } = current;
            return {
              ...data,
              [userID]:current
            }
          }, state.playersData);
          break;

        case 'question':
          state.step = 'question';

          state.question = {
            ...state.question,
            question: param[state.lang]
          };
          break;

        case 'players':
          state.players = param.sort((p1, p2) => p1.timestamp - p2.timestamp);
          break;

        case 'player_answer':
          state.answers = {
            ...state.answers,
            ...param
          };
          break;

        case 'final':
          state.step = 'final'
          state.playersData = param.reduce((data, current, index) => {
            const { userID } = current;
            return {
              ...data,
              [userID]:{
                ...current,
                winner: index === 0
              }
            }
          }, state.playersData);
          break;

        // case 'reset':
        //   window.localStorage.clear();
        //   window.location.reload();
        //   break;

        default:
          return;
      }
    },
    onDisconnection: (state, { payload }) => {
      state.connected = false;

      window._WSConnection.io.connect();
    },
    onConnection: (state, { payload }) => {
      state.connected = true;
    },
    setState: (state, { payload }) => {
      state.step = payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  onMessage,
  onDisconnection,
  onConnection,
  setState
} = stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine.reducer;
