import PlayerItem from "./Item";

const PlayerList = ({ players, isGameStarted }) => {
  const renderPlayers = () => {
    return players.map((player, index) => {
      return (
        <PlayerItem
          key={`p_${index}`}
          {...player}
          isGameStarted={isGameStarted}></PlayerItem>
      );
    });
  };

  return <div className="PlayerList">{renderPlayers()}</div>;
};

export default PlayerList;
