import banner from "./banner.jpg";

const Add = () => {
  return (
    <div class="full-window">
      <img src={banner} style={{ width: "1980px" }} alt="" />
    </div>
  );
};

export default Add;
