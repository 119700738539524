import ProviderWS from "../../Provider";
import stateMachine, {
  initialState,
  onMessage,
  onConnection,
  onDisconnection,
} from "./stateMachine";
import PageRedux from "../../ui/Page/PageRedux";

import StateRouter from "./StateRouter";

import ConnectionIndicator from "../../ui/ConnectionIndicator";

window.updateUnlocked = false;

const Texting = ({
  match: {
    params: { hash, overlay },
  },
}) => {
  const state = {
    ...initialState,
    // step: 'question',
  };

  return (
    <ProviderWS
      namespace="/texting_tv"
      state={state}
      hash={hash}
      autoConnect={true}
      reducers={stateMachine}
      onMessage={onMessage}
      onConnection={onConnection}
      onDisconnection={onDisconnection}
    >
      <PageRedux className="Texting" hash={hash}>
        <StateRouter />

        <div className="Texting__connection-indicator">
          <ConnectionIndicator hidePositive={true} />
        </div>
        <div className="full-window full-window--overlay" />
      </PageRedux>
    </ProviderWS>

  );
};

export default Texting;
