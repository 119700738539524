
import Router from "./Router";
import Quiz from "../workflows/Quiz";
import Texting from "../workflows/Texting";
import Leaderboard from "../workflows/Leaderboard";

const routes = [{
  path: '/quiz/:hash',
  component: Quiz,
  exact: true
},{
  path: '/texting/:hash',
  component: Texting,
  exact: true
},{
  path: '/leaderboard/:hash',
  component: Leaderboard,
  exact: true
}]

function Layout() {
  return (
    <Router routes={routes}/>
  );
}

export default Layout;
