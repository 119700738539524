import { useSelector } from "react-redux";
import { useState } from "react";
import Home from "./pages/Home";
// import Signup from "./pages/Signup";
import Registration from "./pages/Registration";
import Question from "./pages/Question.js";
import EndScreen from "./pages/EndScreen";

import StepTransition from "../../ui/StepTransition";

const stepTransitionMapper = {
  "null:home": "none",
  "final:home": "crossfade",
  "home:home": "static",

  "home:signup": "fade",
  "signup:signup": "static",

  "signup:registration": "fade",
  "registration:registration": "static",

  "registration:question": "crossfade",
  "question:question": "static",

  "question:final": "fade",
  "final:final": "static",

  "final:question": "crossfade"
};

const StateRouter = () => {
  let currentStep = useSelector((state) => state.stateMachine.step);
  const [previousStep, setPreviousStep] = useState("null");

  const stepComponents = {
    home: <Home />,
    signup: <Registration />, //<Signup />,
    registration: <Registration />,
    question: <Question />,
    final: <EndScreen />
  };

  console.log(`${previousStep}:${currentStep}`);

  const animation =
    stepTransitionMapper[`${previousStep}:${currentStep}`] ||
    "static";

  const animationEnd = () => {
    console.log("animation done", currentStep);
    // if (currentStep == "signup") return;
    setPreviousStep(currentStep);
  };

  return (
    <>
      <StepTransition
        animation={animation}
        onAnimationEnd={animationEnd}
        onTransitionEnd={animationEnd}>
        <div className="previous">
          {previousStep !== currentStep &&
            stepComponents[previousStep]}
        </div>
        <div className="current">{stepComponents[currentStep]}</div>
      </StepTransition>
    </>
  );
};

export default StateRouter;
