import winnerIndicator from "../../../../../../assets/decorations/winnerIndicator.png";
import loserIndicator from "../../../../../../assets/decorations/loserIndicator.png";
import crown from "../../../../../../assets/decorations/crown.svg";
import I18n from "../../../../../ui/I18n";

const PlayerItem = ({
  initials,
  positionClassName = "",
  profilePicture,
  correct = null,
  winner = null,
  isRegistered = null,
  isGameStarted = null,
  score = 0,
}) => {
  const isAnswered = correct !== null || winner !== null;
  const greyScale = correct == null || correct ? "" : "loser";
  const isAnsweredClassName = isAnswered ? "" : "--notShow";
  const isRegisteredClassName = isRegistered ? "registered" : "";
  const name = winner ? `${initials}\n` : "";
  const renderAnswerIndicator = () => {
    let image = correct ? winnerIndicator : loserIndicator;
    const className = correct ? "winner" : "loser";

    if (winner !== null) {
      if (winner === false) {
        return;
      }

      image = crown;
    }

    return (
      <div className="PlayerList__item-avatarheader">
        <img
          src={image}
          alt="indicator"
          className={`PlayerList__item-indicator ${className}${isAnsweredClassName}`}
        />
      </div>
    );
  };

  return (
    <div
      key={initials}
      className={`${positionClassName} PlayerList__item ${isRegisteredClassName}`}
    >
      <div className="PlayerList__item-avatar">
        {renderAnswerIndicator()}

        <div className={`PlayerList__item-image ${greyScale}`}>
          <img src={profilePicture} alt={initials} />
        </div>

        {!winner && (
          <div className={`PlayerList__item-initials`}>{initials}</div>
        )}
      </div>
      {isGameStarted && (
        <div
          className={`PlayerList__item-initials`}
          style={{ "white-space": "pre-line" }}
        >
          {name}
          <I18n t="quiz.player.score" params={{ score }} />
        </div>
      )}
    </div>
  );
};

export default PlayerItem;
