import React, { useEffect, useState } from "react";
import I18n from "../../../ui/I18n";
import { useSelector } from "react-redux";
import PlayerItem from "./helpers/player/Item";
import confetti from "./helpers/confetti";
import Wave from "../../../ui/Waves";
import Add from "../../../ui/Add";


const EndScreen = ({ lang }) => {
  let players = useSelector((state) => state.stateMachine.players);
  const [displayWinner, setDisplayWinner] = useState(false);
  const [displayAdd, setDisplayAdd] = useState(false);

  const playersData = useSelector(
    (state) => state.stateMachine.playersData
  );

  let winnerPlayer = {};

  players = players.map((player, index) => {
    if (playersData[player.userID]) {
      const { winner, score } = playersData[player.userID];
      const playerObj = {
        index: index + 1,
        ...player,
        winner,
        score
      };

      if (winner) {
        winnerPlayer = playerObj;
      }
      return playerObj;
    }

    return {
      ...player,
      index: index + 1
    };
  });

  useEffect(() => {
    if (winnerPlayer) {
      confetti();
    }

    setTimeout(() => {
      setDisplayWinner(true);

      setTimeout(()=>{
        setDisplayAdd(true)
      },10000)
    }, 1000);
  });

  const renderPlayers = () => {
    return players.map((player, index) => {
      if (player.winner) {
        return (
          <div
            key={`p_${index}`}
            className={`PlayerList-placeholder ${
              displayWinner && "hide"
            }`}></div>
        );
      } else {
        return (
          <PlayerItem
            key={`p_${index}`}
            {...player}
            isGameStarted={true}
          />
        );
      }
    });
  };

  const renderWinnerPlayer = () => {
    return (
      <div
        className={`Winner Winner-${players.length}-${
          winnerPlayer.index
        } ${displayWinner && "animate"}`}
        style={{ opacity: 0 }}>
        <PlayerItem
          positionClassName={`PlayerList-winner`}
          key={`winer`}
          initials={winnerPlayer.initials}
          score={winnerPlayer.score}
          profilePicture={winnerPlayer.profilePicture}
          winner={displayWinner}
          isGameStarted={true}
          correct={true}
        />
      </div>
    );
  };

  return (
    <div className="EndScreenPage__content">
      <Wave />

      <canvas
        className="EndScreenPage__canvas"
        id="confetti-canvas"></canvas>

      <div className="EndScreenPage__title">
        <I18n t="quiz.endscreen.title" />
      </div>

      <div style={{ height: "525.73px", width: "1920px" }}></div>

      {renderWinnerPlayer()}

      <div className="EndScreenPage__players">
        <div
          className={`PlayerList PlayerList-final-${players.length}`}>
          {renderPlayers()}
        </div>
      </div>
      {displayAdd && <Add />}
    </div>
  );
};

export default EndScreen;
