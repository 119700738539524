import ProviderWS from "../../Provider";
import stateMachine, {
  initialState,
  onMessage,
  onConnection,
  onDisconnection,
} from "./stateMachine";
import PageRedux from "../../ui/Page/PageRedux";

import StateRouter from "./StateRouter";

import ConnectionIndicator from "../../ui/ConnectionIndicator";

window.updateUnlocked = false;

const Leaderboard = ({
  match: {
    params: { hash, overlay },
  },
}) => {
  console.log(`${hash}-game`)
  const currentGame = window.localStorage.getItem(`${hash}-game`)
  const state = {
    ...initialState,
    currentGame
    // step: 'question',
  };

  return (
      <ProviderWS
        namespace={`/${currentGame}_leaderboard`}
        state={state}
        hash={hash}
        autoConnect={true}
        reducers={stateMachine}
        onMessage={onMessage}
        onConnection={onConnection}
        onDisconnection={onDisconnection}
      >

      <PageRedux className="Leaderboard">
        <StateRouter />

        <div className="Leaderboard__connection-indicator">
          <ConnectionIndicator hidePositive={true} />
        </div>
      </PageRedux>
      
      <div className="full-window full-window--overlay" />
    </ProviderWS>
  );
};

export default Leaderboard;
