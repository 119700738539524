import { useSelector } from "react-redux";
import I18n from "../../../ui/I18n";
import PlayerItem from "./helpers/player/Item";
import Waves from "../../../ui/Waves";

const Registration = () => {
  const players = useSelector((state) => state.stateMachine.players);

  const renderPlayers = () => {
    return players.map((player, index) => {
      return (
        <PlayerItem
          key={`p_${index}`}
          {...player}
          isRegistered={true}
          isGameStarted={false}
        />
      );
    });
  };

  return (
    <div className="RegistrationPage__content">
      <Waves />
      <div className="RegistrationPage__title">
        <I18n t="quiz.registration.title" />
      </div>

      <div className="RegistrationPage__players">
        <div className="PlayerList">{renderPlayers()}</div>;
      </div>
    </div>
  );
};

export default Registration;
