import { useState, useEffect } from "react";
import I18n from "../../../ui/I18n";
import { useSelector } from "react-redux";
import Waves from "./helpers/Waves";
import confetti from "./helpers/confetti";

const Player = ({
  player,
  winner = false,
  className = "",
  hide = false,
  animationClasses = "",
  prefixClass = "PodiumPage-list__item",
}) => {
  const [animateClass, setAnimateClass] = useState("");
  if (!player) {
    return null;
  }

  const onAnimationEnd = (e) => {
    if (e.target.getAttribute("hide") === "true") {
      // e.target.remove();
    }
  };

  setTimeout(() => {
    setAnimateClass(animationClasses);
  }, 100);

  return (
    <div
      className={`${prefixClass} ${className} ${animateClass}`}
      hide={hide.toString()}
      winner={winner.toString()}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={`${prefixClass}-image`}>
        <img src={player.profilePicture} alt="" />
      </div>
      <div className={`${prefixClass}-player`}>
        <div className={`${prefixClass}-player-initials`}>
          {player.initials}
        </div>
        <div className={`${prefixClass}-player-score`}>{player.score} pts</div>
      </div>
    </div>
  );
};

const Podium = () => {
  const podium = useSelector((state) => state.stateMachine.podium);
  const oldPodium = useSelector((state) => state.stateMachine.oldPodium);
  const hash = useSelector((state) => state.stateMachine.hash);
  const currentGame = window.localStorage.getItem(`${hash}-game`)

  const [displayConfetti, setDisplayConfetti] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const renderWinner = () => {
    if (oldPodium[0]) {
      if (oldPodium[0]?.userID !== podium[0]?.userID) {
        if (!displayConfetti) {
          // setDisplayConfetti(true);
          // setTimeout(()=>{
          //   setDisplayConfetti(false);
          // }, 5000)
        }

        return [
          <Player
            key={`winner-${podium[0]?.userID}`}
            player={podium[0]}
            winner={true}
            prefixClass="PodiumPage-winner__item"
            className="PodiumPage-winner__item--delay"
          />,
          <Player
            key={`winner-old-${oldPodium[0]?.userID}`}
            player={oldPodium[0]}
            prefixClass="PodiumPage-winner__item"
            winner={true}
            hide={true}
          />,
        ];
      }
    }

    return (
      <Player
        key={`winner-${podium[0]?.userID}`}
        player={podium[0]}
        winner={true}
        prefixClass="PodiumPage-winner__item"
      />
    );
  };

  const renderPlaces = () => {
    return podium.slice(1, 5).map((player, index) => {
      const refIndex = index + 1;
      if (oldPodium[refIndex]) {
        if (oldPodium[refIndex]?.userID !== player?.userID) {
          return (
            <div key={`player-container-${player?.userID}`}>
              <Player
                key={`player-${player?.userID}-${index}`}
                player={player}
                className={''}
                animationClasses={`PodiumPage-list__item-${index} delay`}
              />
              <Player
                key={`player-old-${player?.userID}-${index}`}
                player={oldPodium[refIndex]}
                hide={true}
                className={`PodiumPage-list__item-${index}`}
                animationClasses={`hide`}
              />
            </div>
          );
        }
      }

      return (
        <div key={`player-container-${player?.userID}`}>
          <Player
            key={`player-${player?.userID}`}
            player={player}
            animationClasses={`PodiumPage-list__item-${index}`}
            className={""}
          />
        </div>
      );
    });
  };

  useEffect(() => {
    if (displayConfetti) {
      confetti();
    }
  }, [displayConfetti]);

  useEffect(() => {
    if (shouldAnimate) {
      confetti();
    }
  }, [oldPodium]);

  return (
    <div className="PodiumPage__content">
      <div className="PodiumPage__title">
        <I18n t="leaderboard.podium.title" />
      </div>

      <div className="PodiumPage__sub-title">
        <I18n t={`leaderboard.podium.subtitle.${currentGame}`} />
      </div>
      <div className="PodiumPage-winner">{renderWinner()}</div>
      <div className="PodiumPage-list">{renderPlaces()}</div>
      {displayConfetti && <canvas id="confetti-canvas"></canvas>}
    </div>
  );
};

export default Podium;
