import leaderboardImage from "../../../../assets/leaderboard_home.jpeg";

const Waiting = () => {
  return (
    <div className="WaitingPage__content">
      <img src={leaderboardImage} style={{ width: "1080px" }} alt="" />
    </div>
  );
};

export default Waiting;
