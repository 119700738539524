import { useState } from "react";
import bellLogo from "../../../../assets/logos/bell_logo_texting.png";
import phonesImage from "../../../../assets/decorations/home-visual.png";
import homeVideo from "../../../../assets/videos/tv_home.mp4";

const Home = () => {
  // in case we decide having both page and video displayed change it to `false`
  // and uncomment the setTimeout routine
  const [fullScreen, setFullScreen] = useState(true);

  const handlePlaying = (e) => {
    // setTimeout(() => {
    //   setFullScreen(true);
    // }, 5000);
  };

  return (
    <div className="HomePage__content">
      <div className="HomePage__column">
        <img src={bellLogo} alt="logo" />
      </div>

      <div className="HomePage__column">
        <video
          className={`HomePage__video ${
            fullScreen ? "HomePage__video--fullscreen" : ""
          }`}
          autoPlay={true}
          muted={true}
          loop={true}
          onPlaying={handlePlaying}
        >
          <source src={homeVideo} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <img
          className="HomePage__right-img"
          src={phonesImage}
          alt="decoration"
        />
      </div>
    </div>
  );
};

export default Home;
