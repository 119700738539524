import { useSelector } from "react-redux";
import Timer from "../../../../ui/Timer";
import I18n from "../../../../ui/I18n";
import bellLogoWhite from "../../../../../assets/decorations/bell_logo_white.svg";

import Player from './Player'

const Game = () => {
  const players = useSelector((state) => state.stateMachine.players);


  return (
    <div className='GamePage__container'>
      <div className="GamePage__header">
        <div className="GamePage__header-logo">
          <img alt="logo" src={bellLogoWhite}/>
        </div>
        <div className="GamePage__header-logo">
          <Timer width="100" />
        </div>

        <div className="GamePage__header-text">
          <I18n t='texting.header.texts' DOMElement='div' />
        </div>
      </div>
      <div className="GamePage__players" >
        {players.map((player)=><Player player={player}/>)}
      </div>

    </div>
  );
};

export default Game;
