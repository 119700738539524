import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Hook, Console } from "console-feed";
import Indicator from "../ui/Indicator";
import ConnectionIndicator from "../ui/ConnectionIndicator";

const LogsContainer = () => {
  const [logs, setLogs] = useState([]);

  // run once!
  useEffect(() => {
    Hook(
      window.console,
      (log) => setLogs((currLogs) => [...currLogs, log]),
      false
    );
  }, []);

  return <Console logs={logs} variant="dark" />;
};

const IconUnFocus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM384 80H64C55.16 80 48 87.16 48 96V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V96C400 87.16 392.8 80 384 80z"
    />
  </svg>
);

const IconFocus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M200 344V280H136C122.7 280 112 269.3 112 256C112 242.7 122.7 232 136 232H200V168C200 154.7 210.7 144 224 144C237.3 144 248 154.7 248 168V232H312C325.3 232 336 242.7 336 256C336 269.3 325.3 280 312 280H248V344C248 357.3 237.3 368 224 368C210.7 368 200 357.3 200 344zM0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM48 96V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V96C400 87.16 392.8 80 384 80H64C55.16 80 48 87.16 48 96z"
    />
  </svg>
);

const IconDeleteSession = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z"
    />
  </svg>
);

const Debug = ({ hash, namespace, logs }) => {
  const sessionID = useSelector((state) => state.stateMachine.sessionID);
  const connected = useSelector((state) => state.stateMachine.connected);
  const focused = useSelector((state) => state.stateMachine.focused);

  const toggleConnection = () => {
    if (connected) {
      window._WSConnection.io.disconnect();
    } else {
      window._WSConnection.io.connect();
    }
  };

  const handleDelete = () => {
    if (window.confirm("Deleting session! \n Are you sure?")) {
      window.localStorage.clear();
      window.location.reload();
    }
  };

  return (
    <div className="Toolbar">
      <div className="Toolbar__actions">
        <div
          className={`Toolbar__actions-icon Toolbar__actions-icon--disabled`}
          onClick={handleDelete}
        >
          <IconDeleteSession />
        </div>
        <div className={`Toolbar__actions-id`}>
          {hash}
          {sessionID}
        </div>

        <Indicator
          status={focused}
          positive={<IconFocus />}
          negative={<IconUnFocus />}
        />

        <ConnectionIndicator onClick={toggleConnection} />
      </div>

      {logs && (
        <div className="Toolbar__logs">
          <LogsContainer />
        </div>
      )}
    </div>
  );
};

export default Debug;
