import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  step: "waiting",
  lang: "en-CA",
  secondsLeft: 5,
  currentGame: "quiz",
  podium: [],
  oldPodium: [],
  countdown: {
    total: 10,
    leftTime: 5,
  },
};

export const stateMachine = createSlice({
  name: "stateMachine",
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param } }) => {
      console.log(
        type,
        JSON.stringify({
          type: "stateMachine/onMessage",
          payload: { type, param },
        })
      );

      switch (type) {
        case "state":
          if (param == "signup") {
            param = "podium";
          }
          if (param == "home") {
            param = "waiting";
          }

          state.step = param;
          break;

        case "podium":
          state.oldPodium = state.podium;
          state.podium = param;
          break;

        default:
          return;
      }
    },
    onDisconnection: (state, { payload }) => {
      state.connected = false;

      window._WSConnection.io.connect();
    },
    onConnection: (state, { payload }) => {
      state.connected = true;
    },
    setState: (state, { payload }) => {
      state.step = payload;
    },
    gameChange: (state, { payload }) => {
      state.currentGame = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onMessage, onDisconnection, onConnection, setState } =
  stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine.reducer;
